export const EmailConfirmIT = {
  backToWelevel: "Torna su welevel",
  fetching: {
    title: "Stiamo confermando la tua mail!",
    body: "Attendi qualche istante, non chiudere questa pagina.",
  },
  success: {
    title: "Abbiamo confermato la tua mail!",
    body:
      "La tua mail è stata confermata con successo! Benvenuto nel mondo welevel!",
  },
  failure: {
    title: "C'è stato qualche imprevisto",
    body: "Non siamo riusciti a collegare la tua email",
  },
};
