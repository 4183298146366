export const CheckoutIT = {
  coupon: {
    label: "Hai un codice sconto?",
    helperText: "Inserisci un codice SCONTO se lo hai.",
    successPercentage: "Sconto del {{percentage}}% applicato.",
    successAmount: "Sconto di {{amount}} applicato.",
    expired: "Coupon NON VALIDO o SCADUTO",
  },

  paymentMethod: {
    title: "Metodo di pagamento",
    new: "Aggiungi un metodo di pagamento",

    buttons: {
      add: "Aggiungi",
      processing: "Lavorazione in corso...",
      success: "Aggiunto nuovo metodo di pagamento",
      reload: "Ricarica",
    },

    types: {
      creditCard: "Carta di Credito",
      payPal: "PayPal",
    },

    errors: {
      general: "Errore nell'aggiunta del un nuovo metodo di pagamento",
      gateway: "Errore del gateway, ritentare più tardi.",
      auth3D: "Autorizzazione 3D Secure 2 fallita, ritentare",
    },

    success: "Nuovo metodo di pagamento salvato!",
  },

  buttons: {
    pay: "Paga {{amount}}",
    continue: "Prosegui",
  },

  successModal: {
    title: "Acquisto completato",
    body: "Grazie per aver scelto welevel.academy per la tua formazione.",
    invoice: "Se hai bisogno di fattura richiedila ora.",
    actions: {
      invoice: "Richiedi fattura",
      continue: "Chiudi",
    },
  },

  policy:
    "Acquistando aderisci ai <url>Termini e condizioni</url> della piattaforma",

  help: [
    //"Alcuni metodi di pagamento richiedono una <strong>pre-autorizzazione</strong> per verificarne la validità e una successiva autorizzazione per l’acquisto. Non verrà effettuato alcun addebito confermando la pre-autorizzazione.",
    //"Hai un <strong>codice sconto</strong>? Inseriscilo dopo aver aggiunto il metodo di pagamento e completato le fasi di autorizzazione.",
    "Per alcuni metodi di pagamento è richiesta una <strong>pre-autorizzazione</strong> per verificarne la validità e una successiva autorizzazione per l’acquisto. La <strong>pre-autorizzazione</strong> non genera nessuna transazione, quindi nessun addebito.",
    "Hai un <strong>codice sconto</strong>? Inseriscilo dopo aver aggiunto il metodo di pagamento e completato le fasi di autenticazione",
    "Non riesci ad aggiungere il metodo di pagamento o completare l’acquisto? Contattaci a <url>info@example.com</url> per ricevere assistenza.",
  ],

  steps: {
    confirm: "Riepilogo",
    chooseMethod: "Scelta del metodo di pagamento",
    newMethod: "Aggiungi un metodo di pagamento",
  },

  summary: {
    title: "Riepilogo",
    order: "Ordine",
    amount: "Prezzo originale",
    discount: "Sconto",
    total: "Totale",
  },

  invoiceForm: {
    compileFields: "Compila i seguenti campi",
    success: "Richiesta inviata con successo",
    sending: "Sto inviando la richiesta",
    error:
      "C'è stato un problema nell'invio della richiesta. Contattaci a fatturazione@example.com",
  },

  errors: {
    general: "C'è stato un problema, impossibile continuare.",
  },
};
