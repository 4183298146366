export const EmailConfirmEN = {
  backToWelevel: "Back to welevel",
  fetching: {
    title: "We are confirming your email!",
    body: "Wait a few moments, do not close this page.",
  },
  success: {
    title: "Abbiamo confermato la tua mail!",
    body: "We have confirmed your email!",
  },
  failure: {
    title: "There has been some unforeseen",
    body: "We were unable to link your email",
  },
};
