export const LessonIT = {
  lessonCompleted: {
    title: "Congratulazioni!",
    description: "Hai completato questa lezione. Procedi alla prossima!",
    cta: "Prossima lezione",
  },
  lessonUpsell: {
    freeLesson: {
      title: "Lezione gratuita",
      description: "Accedi o Registrati per guardarla subito!",
    },
    paidLesson: {
      title: "Scegli l'accesso illimitato Welevel Unlimited",
      description: `Migliora velocemente ogni giorno grazie a più di 2500 corsi per
        professionisti del turismo e dell'ospitalità`,
    },
  },
};
