export const SettingsEN = {
  seo: {
    title: "Settings | universo corsi",
  },

  toolbar: {
    dashboard: "Dashboard",
    removeCover: "Remove cover",
  },

  dialog: {
    removeCover: {
      message: "Remove cover image",
      confirmation: "Are you sure you want to remove the cover image?",
    },
  },

  anagraficaTitle: "Biography",
  marketingTitle: "About you",
  notificationTitle: "New content and updates",
  accountTitle: "Account",

  anagrafica: {
    billingType: "Business Type",
    billingTypeOptions: {
      label: "Business Type",
      personal: "Private",
      freelancer: "Freelance",
      signlebiz: "Single Business",
      business: "Business",
    },

    agencyName: "Business Name",
    sdi: "Digital billing code (SDI/CUD)",

    address: "Address",
    billing: "Billing",
  },

  marketing: {
    companyRole: "Role in the company",
    companyDivision: "Corporate department",
    companyType: "Type of company",
    livingProvince: "Living province/city",
  },

  notification: {
    improveService: "Improving service",
    newContentAndUpdates: "New content and updates",

    improveServiceAcceptance:
      "I consent to data processing for automatic data processing, statistical purposes and service improvement",
    newContentAndUpdatesAcceptance:
      "I consent to data processing for direct marketing purposes",
  },

  changeEmail: {
    title: "Change email address",
    body: "Do you want to change your <b>email address.</b>?<br />We'll send you an email with a link to confirm the new address.",
  },

  passwordReset: {
    title: "Password reset",
    body: "If you want to change your login password press here. <br />We will send you an email with a link that will take you to the page where you can change it.",
    button: "Password reset",
    confirmationDialog: "Enter your current password to confirm",
  },

  deleteAccount: {
    title: "Danger zone",
    body: "Do you wish to delete <b>all</b> your welevel data <b>forever</b>?",
    button: "Delete Account",
    confirmationDialog:
      "Insert you password to confirm and proceed with the deletion",
  },

  testVideoPlayer: {
    title: "Test new video player",
    body: "We are testing a new video player to fix some streaming issues.<br />This player is still in testing and may <b>have problems</b> and <b>automatically disable itself</b>.<br /><b>Your opinion is important</b>, let us know your experience by writing to <a>info@example.com</a>.",
    switch: "Try the new video player",
  },

  updating: {
    error: "There was an error when updating the profile",
    success: "Profile successfully updated",
  },
};
